<template>
    <div class="Detail">
		<Header></Header>
		<div class="mainBox">
			<div class="mainTop">
				<div class="mainLeftBox">
					<el-image
						style="width:100%;height:100%;"
					    :src="obj.pnImage"
					    :preview-src-list="srcList"
					    :initial-index="4"
					    fit="contain"
						v-if="showType=='0'"
					/>
					<video
						style="width:100%;height:600px;object-fit: fill;"
						autoplay="" 
						controls="" 
						controlslist="nodownload" 
						loop="" 
						preload="auto" 
						:src="obj.pnFile" 
						v-if="showType=='1'"
					>
						您的浏览器不支持 video 标签。
					</video>
					<audio  
						autoplay=""
						controls=""
						style="width:100%;height:600px;"
						:src="obj.pnFile" 
						v-if="showType=='1'"
					>
						您的浏览器不支持 audio 标签。
					</audio>
					<div class="play" v-if="type !='img'" @click="playBtn">
						<img 
							style="width:50px;height:50px;" 
							v-if="play == false" 
							src="../../../assets/img/play.png"
						>
						<img 
							style="width:50px;height:50px;" 
							v-if="play == true" 
							src="../../../assets/img/isPlay.png"
						>
					</div>
				</div>
				<div class="mainRightBox">
					<div class="title">{{obj.pnName}}</div>
					<div class="IconImg">
						<span style="font-weight:bold;">Token ID</span> : {{obj.pnTokenId}}
					</div>
					<div class="album" @click="albumBtn">
						专辑 : <font color="#409EFF">{{obj.cnName}}</font>
					</div>
					<div class="timeBox">
						<div class="money" style="color:#000;text-decoration:line-through;" v-if="obj.ssId != 'nft_production_1'">暂不出售</div>
						<div class="money" v-else>
							<span v-if="pnType=='0'">￥{{obj.pnPrice}}</span>
							<span v-else>均价:￥{{obj.pnPrice}}</span>
						</div>
						<div class="time" v-if="obj.pnSale != ''">
							出售剩余时间 : 
							<span v-html="timeVal"></span>
						</div>
					</div>
					<div class="author">
						<span @click="authorBtn">
							作者：<font>{{obj.pnAuthorName}}</font>
						</span>
						<span v-if="pnType == '0'" @click="ownerBtn">
							&nbsp;&nbsp;持有人：<font>{{obj.pnOwnerName}}</font>
						</span>
					</div>
					<div class='works'>作品简介</div>
					<p>{{obj.pnDes}}</p>
					<div class="IconBtn">
						<el-button-group class="ml-4">
							<el-tooltip
								class="box-item"
								effect="dark"
								content="打开外部链接"
								placement="top"
								v-if="obj.pnExternal != ''"
							>
								<el-button @click="goToback()">
									<img style="width:25px;height:25px;" src="../../../assets/img/Web.svg">
								</el-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								effect="dark"
								content="分享"
								placement="top"
							>
								<el-button @click="onCopy">
									<Icon icon="Share"></Icon>
								</el-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								effect="dark"
								content="举报"
								placement="top"
							>
								<el-button>
									<el-dropdown>
										<span class="Icon">
											<Icon icon="Menu"></Icon>
										</span>
										<template #dropdown>
											<el-dropdown-menu>
												<el-dropdown-item @click="reportBtn">举报</el-dropdown-item>
											</el-dropdown-menu>
										</template>
									</el-dropdown>
								</el-button>
							</el-tooltip>
						</el-button-group>
					</div>
					<div class="button">
						<div class="btn">
							<el-button type="primary" :disabled="disabled" @click="buyBtn(obj.pnId,obj.pnType)">立即购买</el-button>
							<el-button type="primary" plain @click="offerBtn(obj.pnId,obj.pnType)">出价</el-button>
						</div>
						<div class="Icon">
							<span @click="collectBtn">
								<img v-if="obj.urId==''" :src="Image">
								<img v-else :src="loveCur">
								{{obj.pnFavorited}}
							</span>
							<span style="margin-left:10px;" v-if="pnType=='1'">
								<img :src="IconGrid"/>多版本
							</span>
							<span style="margin-left:10px;" v-if="obj.pnMatter=='1'">
								<img src="../../../assets/img/selected.svg">实物
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="momeyBox" v-if="pnType == '1'">
				<div class="name">版号</div>
			</div>
			<div class="version" v-if="pnType == '1'">
				<div>
					版本数量&nbsp;&nbsp;<font>{{versionRows.length}}</font>&nbsp;&nbsp;
					在售&nbsp;&nbsp;<font>{{saleTotal}}</font>
				</div>
				<div class="list">
					<template v-for="(item,index) in versionRows" :key="index">
						<div :class="item.ssId =='nft_production_1'?'box':''" @click="versionBtn(item.pnId)">{{item.pnNumb}}</div>
					</template>
				</div>
			</div>
			<div class="momeyBox">
				<div class="name">价格</div>
			</div>
			<div class="priceBox" style="padding:0px 20px;">
				<div class="price" style="margin-bottom:10px;">
					历史平均价格&nbsp;&nbsp;<font>{{avg}}</font>
				</div>
				<div class="select">
					周期:
					<el-dropdown trigger="click" @command="handleCommand">
					    <span class="el-dropdown-link">
					      {{typeName}}
					      <el-icon :size="18" class="el-icon--right">
					        <Icon icon="ArrowDown"></Icon>
					      </el-icon>
					    </span>
					    <template #dropdown>
					      <el-dropdown-menu>
					        <el-dropdown-item command="0_7天">7天</el-dropdown-item>
					        <el-dropdown-item command="1_14天">14天</el-dropdown-item>
					        <el-dropdown-item command="2_30天">30天</el-dropdown-item>
					        <el-dropdown-item command="3_60天">60天</el-dropdown-item>
					        <el-dropdown-item command="4_90天">90天</el-dropdown-item>
					        <el-dropdown-item command="5_所有时间">所有时间</el-dropdown-item>
					      </el-dropdown-menu>
					    </template>
					</el-dropdown>
				</div>
			</div>
			<div class="statisticalBox" style="height:400px;padding:0px 20px;">
				<div style="text-align:center;" v-if="data.length == 0">
					<img src="../../../assets/img/noData.png">
				</div>
				<div id="container" v-else></div>
			</div>
			<div class="momeyBox">
				<div class="name">历史</div>
			</div>
			<!-- 单品 -->
			<div class="boxListCon" style="height:400px;overflow-y:auto;" ref="scrollEvent" v-if="pnType == '0'">
				<el-table :data="tableData" 
					style="width:100%;height:380px;"
				>
					<el-table-column width="150">
						<template #default="scope">
							<div class="cart">
								<img class="IconHistory" :src="IconBuy" v-if="scope.row.rteId == '5'">
								<img class="IconHistory" :src="IconHandUp" v-if="scope.row.rteId == '4'">
								<img class="IconHistory" :src="IconShelve" v-if="scope.row.rteId == '3'">
								<img class="IconHistory" :src="IconCart" v-if="scope.row.rteId == '2'">
								<img class="IconHistory" :src="IconGive" v-if="scope.row.rteId == '1'">
								<img class="IconHistory" :src="IconBuild" v-if="scope.row.rteId == '0'">
								{{scope.row.rteName}}
							</div>
						</template>
					</el-table-column>
					<el-table-column label="价格" width="200">
						<template #default="scope">
							<font style="font-size: 20px;font-weight:bold;color:red;" v-if="scope.row.rsPrice === '0.00'">
								---
							</font>
							<font style="font-size: 20px;font-weight:bold;color:red;" v-else>
								￥{{scope.row.rsPrice}}
							</font>
						</template>
					</el-table-column>
					<el-table-column label="发送方">
						<template #default="scope">
							<div class="user" v-if="scope.row.rsFromName != ''">
								<span>
									<img :src="scope.row.rsFromHead ">
								</span>
								<span class="text">
									<div>{{scope.row.rsFromName}}</div>
								</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="接收方">
						<template #default="scope">
							<div class="user" v-if="scope.row.rsToName != ''">
								<span>
									<img :src="scope.row.rsToHead">
								</span>
								<span class="text">
									<div>{{scope.row.rsToName}}</div>
								</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="时间" width="250">
						<template #default="scope">
								<span style="font-weight:bold;font-size:16px;">{{formattDate(scope.row.rsTime)}}</span>
						</template>
					</el-table-column>
				  </el-table>
			</div>
			<!-- 多版本 -->
			<div class="boxListCon" style="height:400px;overflow-y:auto;" ref="scrollEvent" v-else>
				<el-table :data="tableData" 
					style="width:100%;"
					 ref="scroll"
				>
					<el-table-column width="150">
						<template #default="scope">
							<div class="cart">
								<img class="IconHistory" :src="IconBuy" v-if="scope.row.rteId == '5'">
								<img class="IconHistory" :src="IconHandUp" v-if="scope.row.rteId == '4'">
								<img class="IconHistory" :src="IconShelve" v-if="scope.row.rteId == '3'">
								<img class="IconHistory" :src="IconCart" v-if="scope.row.rteId == '2'">
								<img class="IconHistory" :src="IconGive" v-if="scope.row.rteId == '1'">
								<img class="IconHistory" :src="IconBuild" v-if="scope.row.rteId == '0'">
								{{scope.row.rteName}}
							</div>
						</template>
					</el-table-column>
					<el-table-column label="价格" width="200">
						<template #default="scope">
							<font style="font-size: 20px;font-weight:bold;color:red;" v-if="scope.row.rsPrice === '0.00'">
								---
							</font>
							<font style="font-size: 20px;font-weight:bold;color:red;" v-else>
								￥{{scope.row.rsPrice}}
							</font>
						</template>
					</el-table-column>
					<el-table-column label="版号" width="250">
						<template #default="scope">
								<span style="font-weight:bold;font-size:16px;">{{scope.row.pnNumb}}</span>
						</template>
					</el-table-column>
					<el-table-column label="发送方">
						<template #default="scope">
							<div class="user" v-if="scope.row.rsFromName != ''">
								<span>
									<img :src="scope.row.rsFromHead ">
								</span>
								<span class="text">
									<div>{{scope.row.rsFromName}}</div>
								</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="接收方">
						<template #default="scope">
							<div class="user" v-if="scope.row.rsToName != ''">
								<span>
									<img :src="scope.row.rsToHead">
								</span>
								<span class="text">
									<div>{{scope.row.rsToName}}</div>
								</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="时间" width="250">
						<template #default="scope">
								<span style="font-weight:bold;font-size:16px;">{{formattDate(scope.row.rsTime)}}</span>
						</template>
					</el-table-column>
				  </el-table>
			</div>
			<!-- 持有人 -->
			<div class="momeyBox" v-if="pnType == '1'">
				<div class="name">持有人</div>
			</div>
			<div class="ownerBox" v-if="pnType == '1'">
				<div class="owner">
					持有人数&nbsp;&nbsp;<font>{{owers}}</font>
					在售&nbsp;&nbsp;<font>{{sales}}</font>
				</div>
				<div class="owner">
					<el-checkbox v-model="checked" label="只看在售" size="large" @change="changeBox"/>
				</div>
			</div>
			<div class="boxListCon" v-if="pnType == '1'" style="height:400px;overflow-y:auto;" ref="scrollEventOwner">
				<el-table :data="tableDataOwers" style="width: 100%">
					<el-table-column  label="版号" width="150">
						<template #default="scope">
							<div class="cart">
								{{scope.row.pnNumb}}
							</div>
						</template>
					</el-table-column>
					<el-table-column label="价格" width="200">
						<template #default="scope">
							<font style="font-size:20px;font-weight:bold;color:red;" v-if="scope.row.pnPrice === '0.00'">
								---
							</font>
							<font style="font-size:20px;font-weight:bold;color:red;" v-else>
								￥{{scope.row.pnPrice}}
							</font>
						</template>
					</el-table-column>
					<el-table-column label="持有人">
						<template #default="scope">
							<div class="user" v-if="scope.row.pnOwnerName != ''">
								<span>
									<img :src="scope.row.pnOwnerHead ">
								</span>
								<span class="text">
									<div>{{scope.row.pnOwnerName}}</div>
								</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="最近交易时间" width="250">
						<template #default="scope">
							<span style="font-weight:bold;font-size:16px;">{{formattDate(scope.row.pnDeal)}}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="100">
						<template #default="scope">
							<el-button 
								v-if="scope.row.ssId=='nft_production_1'" 
								style="color:#fff;height:30px;" 
								type="primary"
								:disabled="disabled"
								@click="buyBtn(scope.row.pnId,scope.row.pnType)"
							>
								购买
							</el-button>
						</template>
					</el-table-column>
				  </el-table>
			</div>
			<!-- 属性 -->
			<div class="momeyBox">
				<div class="name">属性</div>
			</div>
			<div class="nature" style="height:400px;">
				<ul>
					<template v-for="item in pnPropertiesArr">
						<li v-if="item.key != '' && item.value != ''">
							<div>{{item.key}}</div>
							<div>{{item.value}}</div>
						</li>
					</template>
				</ul>
			</div>
			<!-- 推荐 -->
			<div class="momeyBox">
				<div class="name">同专辑内其他作品</div>
			</div>
			<div style="height:auto;overflow: hidden;">
				<div class="card-carousel--card" style="width:298px;" v-for="(item,index) in recommendRows" @click="DetailBtn(item.pnId,item.pnType)">
					<div class="card-carousel-box">
						<el-image  style="width:298px;height:298px;object-fit: cover;" :src="item.pnImage" :fit="fit"/>
					</div>
					<div class="card-carousel--card--footer">
						<div class="name">
							{{item.pnName}}
						</div>
						<div class="Id img" style="color: rgb(112, 122, 131) !important;">
							<span style="font-weight:bold;">
								<img src="../../../assets/img/id.svg">
							</span>
							{{item.pnTokenId}}
						</div>
						<div class="Id" style="color:red;font-size: 16px;text-align: right;">
							<span v-if="item.pnPrice != '0'">¥{{item.pnPrice}}</span>
						</div>
					</div>
					<div class="IconImgList">
						<span>
							<font v-if="item.pnType == '2'">#{{item.pnNumb}}</font>
						</span>
						<span>
							<font @click.stop="collectListBtn(item.urId,item.pnId,item.pnFavorited,index)">
								<img :src="Image" v-if="item.urId == ''">
								<img :src="loveCur" v-else>
								{{item.pnFavorited}}
							</font>
						</span>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
    </div>
	<el-dialog
	    width="600px"
		title="提示"
		top="7vh"
	    v-model="dialogReport"
	    :before-close="handleClose"
	    :close-on-press-escape="false"
	    :close-on-click-modal="false"
	    :show-close="false"
	>
		<div style="height:550px;overflow-y:auto;">
			<div style="margin:15px 0px;">举报原因</div>
			<div v-for="(item,index) in reasonRows" :key="index">
				<el-radio v-model="trnId" :label="item.trnId" size="large" @change="changeTrnId(item)">{{item.trnReason}}</el-radio>
			</div>
			<div style="margin:15px 0px;">举报说明</div>
			<el-input v-model="tsDes" show-word-limit maxlength="200" :rows="4" type="textarea" placeholder="请输入描述" />
			<div style="margin:15px 0px;">举报图片</div>
			<el-upload
			    list-type="picture-card"
				:limit="3"
			    :action="uploadUrl"
				:headers="headers"
			    :on-success="successUploadReason"
			    :before-upload="beforeAvatarUploadReasonr"
			    :on-remove="handleRemove"
			    :file-list="fileList"
			>
				<Icon icon="Plus"></Icon>
			</el-upload>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogReport = false">取消</el-button>
	        <el-button type="primary" :loading="loadingReason" @click="reasonBtn">举报</el-button>
	      </span>
	    </template>
	</el-dialog>
	<el-dialog
	    width="500px"
		title="出价"
	    v-model="dialogVisible"
	    :before-close="handleClose"
	    :close-on-press-escape="false"
	    :close-on-click-modal="false"
	    :show-close="false"
		:destroy-on-close="true"
		custom-class="dialogSell"
	>	
		<div style="width:450px;">
			<div class="title">可用余额:&nbsp;&nbsp;&nbsp;&nbsp;<font color=red>￥{{urAmtUsable}}</font></div>
			<div class="title">手续费:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{poundage}}%</div>
			<div class="title">支付金额:&nbsp;&nbsp;&nbsp;&nbsp;<font color=red>￥{{amountVal}}</font></div>
			<div class="title">出价价格</div>
			<el-input type="number" :min="1" v-model="porPrice" placeholder="请输入价格" @input="changeVal">
			    <template #prepend>￥</template>
			</el-input>
			<div class="title">交易密码</div>
			<el-input type="password" v-model="urPayPass" placeholder="交易密码"/>
			<div class="Address" v-if="obj.pnMatter=='1'">
				<div>收货地址</div>
				<div @click="drawerAddress = true">更换收货地址</div>
			</div>
			<div class="AddressBox" v-if="obj.pnMatter=='1'">
				{{ruleForm.asPerson}} {{ruleForm.peName}} {{ruleForm.cyName}} {{ruleForm.asAddress}} {{ruleForm.isAsMobile}}
			</div>
			<div style="margin-top:10px;">
				确认出价后，系统将锁定您的出价金额24小时，卖家确认后，系统将为您自动下单并完成交易。如果卖家拒绝您的出价或者24小时后无响应，系统会将您的锁定金额退还至余额。如果卖家拒绝您的出价3次，您将无法再次出价，请谨慎操作
			</div>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="cancelBtn">取消</el-button>
	        <el-button type="primary" :loading="loading" @click="confirmBtn">确认出价</el-button>
	      </span>
	    </template>
	</el-dialog>
	<el-drawer
	    v-model="drawerAddress"
	    title="选择地址"
	    size="30%"
		:destroy-on-close="true"
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
	>
	    <div class="drawerCon" :style="{'height':drawerHeight,'overflow-y':'auto','overflow-x':'hidden'}">
			<List ref="List"  @closeAddress="closeAddress"></List>
		</div>
		<div class="drawerFooter" style="width:30%;">
			<el-button @click="drawerAddress = false">取消</el-button>
			<el-button type="primary" @click="uploadAddress" :loading="loading">确定</el-button>
		</div>
	</el-drawer>
</template>
<script>
	import Image from "../../../assets/img/Love.png"
	import loveCur from "../../../assets/img/loveCur.png"
	import IconBuy from "../../../assets/img/icon_buy.svg"
	import IconHandUp from "../../../assets/img/hand_up.svg"
	import IconCart from "../../../assets/img/cart.svg"
	import IconShelve from "../../../assets/img/shelve.svg"
	import IconGive from "../../../assets/img/give.svg"
	import IconBuild from "../../../assets/img/build.svg"
	import IconGrid from "../../../assets/img/grid.svg"
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import List from './Edit/List'
	import Icon from "@/components/Icon"
	import { ElLoading } from 'element-plus'
	import { Chart } from '@antv/g2'
	export default {
		components:{ Header,Footer,Icon,List },
		data() {
			return{
				uploadUrl:this.$uploadUrl +'common/oss/uploadTemp',
				headers:{
					token: window.localStorage.getItem("token")
				},
				Image: Image,
				loveCur:loveCur,
				IconBuy:IconBuy,
				IconHandUp:IconHandUp,
				IconCart:IconCart,
				IconShelve:IconShelve,
				IconGive:IconGive,
				IconBuild:IconBuild,
				IconGrid:IconGrid,
				obj:{},
				data:[],
				srcList:[],
				avg:'',
				tsDes:'',
				pnType:'',
				type:'0',
				typeName:'7天',
				countTime:0,
				timeVal:'',
				drawerAddress:false,
				dialogVisible:false,
				loading:false,
				loadingReason:false,
				dialogReport:false,
				setIntervalTime:null,
				chart:null,
				currentPage: 1,
				total: 0,
				pageSize:30,
				totalOwner: 0,
				pageSizeOwner:30,
				currentPageOwner: 1,
				tableData:[],
				pnPropertiesArr:[],
				imgArr:[],
				reasonRows:[],
				trnReason:'',
				trnId:'',
				versionRows:[],
				saleTotal:0,
				ssId:'',
				checked:false,
				owers:'',
				sales:'',
				owersTotal:0,
				tableDataOwers:[],
				recommendRows:[],
				showType:'0',
				play:false,
				type:null,
				disabled:false,
				poundage:'',
				urAmtUsable:'',
				porPrice:'',
				urPayPass:'',
				ruleForm:{
					asId:'',
					asPerson:'',
					asMobile:'',
					peId:'',
					peName:'',
					cyId:'',
					cyName:'',
					dtId:'',
					dtName:'',
					asAddress:'',
					asDefault:'1',
					urId:window.localStorage.getItem("urId")
				}
			}
		},
		watch:{
			'$route': function (to, from) {
				let that = this;
				let fullPath = to.name;
				if(fullPath == 'Content.Content'){
					that.disabled = false;
					that.chart = null;
					that.currentPage = 1;
					that.currentPageOwner = 1;
					that.data = [];
					that.tableData = [];
					that.tableDataOwers = [];
					that.recommendRows = [];
					that.getObject();
					that.getPriceStatistical();//价格统计
					that.getListHistory()//历史
					that.pnType = that.$route.params.pnType;
					if(that.pnType == "1"){
						that.getListVersion()//版号
						that.getListOwner()//持有人
					}
				}
		　　}
		},
		computed:{
			amountVal:function () {
				return (Number(this.porPrice) + (Number(this.poundage)/100 * Number(this.porPrice))).toFixed(2);
			}
		},
		created(){
			let that = this;
			that.getObject();
			that.getPriceStatistical();//价格统计
			that.getListHistory()//历史
			that.getService();//手续费
			that.getBalance();//可用余额
			that.pnType = that.$route.params.pnType;
			if(that.pnType == "1"){
				that.getListVersion()//版号
				that.getListOwner()//持有人
			}
		},
		methods:{
			confirmBtn(){
				let that = this;
				let porPrice = that.porPrice;
				let urPayPass = that.urPayPass;
				if(porPrice == ""){
					that.$message.error("请输入价格!");
					return false;
				}
				if(urPayPass == ""){
					that.$message.error("请输入支付密码!");
					return false;
				}
				if(Number(that.urAmtUsable) < Number(that.amountVal)){
					that.$message.error("当前可用余额小于支付价格！");
					return false;
				}
				that.loading = true;
				that.$axios({
					method:'post',
					url: 'nft/por/save',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						porPrice: porPrice,
						urPayPass: urPayPass,
						porAddress: that.ruleForm.peName+','+that.ruleForm.cyName + ',' + that.ruleForm.dtName +','+that.ruleForm.asAddress,
						porPerson: that.ruleForm.asPerson,
						porMobile: that.ruleForm.asMobile,
						pnId: that.obj.pnId
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("出价成功!");
						that.urPayPass = "";
						that.porPrice = "";
						that.dialogVisible = false;
					}else{
						that.$message.error(msg);
					}
					that.loading = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			changeVal(val){
				if(Number(val) <= 0){
					this.porPrice = 1;
				}
			},
			closeAddress(ruleForm){
				this.ruleForm = ruleForm;
				this.drawerAddress = false;
			},
			uploadAddress(){
				this.$refs.List.uploadAddress();
			},
			cancelBtn(){
				let that = this;
				that.urPayPass = "";
				that.porPrice = "";
				that.dialogVisible = false;
			},
			authorBtn(){
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || urId == null){
					this.$router.push({
						path:'/OthersMain/User/'+ this.obj.pnAuthor +'/'+ this.obj.pnAuthorName,
					})
				}else{
					if(urId == this.obj.urId){
						this.$router.push({
							path:'/Main/User',
						})
					}else{
						this.$router.push({
							path:'/OthersMain/User/'+ this.obj.pnAuthor +'/'+ this.obj.pnAuthorName,
						})
					}
				}
			},
			ownerBtn(){
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || urId == null){
					this.$router.push({
						path:'/OthersMain/User/'+ this.obj.pnOwner +'/'+ this.obj.pnOwnerName,
					})
				}else{
					if(urId == this.obj.urId){
						this.$router.push({
							path:'/Main/User',
						})
					}else{
						this.$router.push({
							path:'/OthersMain/User/'+ this.obj.pnOwner +'/'+ this.obj.pnOwnerName,
						})
					}
				}
			},
			offerBtn(pnId,pnType){
				let that = this;
				let urId = window.localStorage.getItem("urId");
				that.disabled = true;
				if(urId == undefined || urId == null){
					this.$confirm('您还未登录，是否现在去登录', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.$router.push(({
							name:"Login"
						}))
					})
					.catch(() => {});
					that.disabled = false;
					return false;
				}
				if(that.obj.ssId != "nft_production_1"){
					that.$message.error("当前作品已售出或下架!");
					return false;
				}
				if(pnType == "1"){
					that.$message.error("请选择版号购买!");
					return false;
				}
				if(urId == that.obj.pnOwner){
					that.$message.error("不能出价购买自己的作品!");
					return false;
				}
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url: 'user/ur/checkPass',
					data:this.$qs.stringify({
						urId: urId
					})
				})
				.then((res)=>{
					let payPass = res.data.payPass;
					that.disabled = false;
					if(payPass == "1"){
						that.dialogVisible = true;
					}else{
						this.$confirm('您当前未设置支付密码，请设置支付密码!', '提示', {
							confirmButtonText: '去设置',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {
							window.localStorage.setItem("selectMenu","/Setting/Secure/PayPass");
							window.localStorage.setItem("selectName","安全,支付密码");
							this.$router.push({
								name:"Setting.Basic"
							})
						})
						.catch(() => {});
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			buyBtn(pnId,pnType){
				let that = this;
				let urId = window.localStorage.getItem("urId");
				that.disabled = true;
				if(urId == undefined || urId == null){
					this.$confirm('您还未登录，是否现在去登录', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.$router.push(({
							name:"Login"
						}))
					})
					.catch(() => {});
					that.disabled = false;
					return false;
				}
				if(that.obj.ssId != "nft_production_1"){
					that.$message.error("当前作品已售出或下架!");
					return false;
				}
				if(pnType == "1"){
					that.$message.error("请选择版号购买!");
					return false;
				}
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url: 'user/ur/checkPass',
					data:this.$qs.stringify({
						urId: urId
					})
				})
				.then((res)=>{
					let payPass = res.data.payPass;
					that.disabled = false;
					if(payPass == "1"){
						that.$router.push({
							path:'/Order/'+ pnId,
						})
					}else{
						this.$confirm('您当前未设置支付密码，请设置支付密码!', '提示', {
							confirmButtonText: '去设置',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {
							window.localStorage.setItem("selectMenu","/Setting/Secure/PayPass");
							window.localStorage.setItem("selectName","安全,支付密码");
							this.$router.push({
								name:"Setting.Basic"
							})
						})
						.catch(() => {});
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			playBtn(){
				let play = this.play;
				let type = this.type;
				if(play== false){
					this.play = true;
					this.showType = "0";
					if(type == "video"){
						this.showType = "1";
					}
					if(type == "audio"){
						this.showType = "2";
					}
				}else{
					this.play = false;
					this.showType = "0";
				}
			},
			versionBtn(pnId){
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || null){
					urId = "";
				}
				this.$router.push({
					path:'/Content/SubContent/'+ pnId,
				})
			},
			DetailBtn(pnId,pnType){
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || null){
					urId = "";
				}
				if(pnType == '0' || pnType == "1"){
					this.$router.push({
						path:'/Content/Content/'+ pnId +'/'+ pnType,
					})
				}
			},
			collectListBtn(urId,pnId,pnFavorited,index){
				let that = this;
				let url = "nft/pfe/save"
				let urIdStorage = window.localStorage.getItem("urId");
				if(urIdStorage == undefined || urIdStorage == null){
					this.$confirm('您还未登录，是否现在去登录', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.$router.push(({
							name:"Login"
						}))
					})
					.catch(() => {});
					return false;
				}
				if(urId != ""){
					url = "nft/pfe/delete"
				}
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url: url,
					data:this.$qs.stringify({
						urId: urIdStorage,
						pnId: pnId,
						urName: window.localStorage.getItem("urName"),
						urHead: window.localStorage.getItem("urHead"),
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("操作成功！");
						if(urId == ""){
							that.recommendRows[index].urId = "12347";
							that.recommendRows[index].pnFavorited = Number(that.recommendRows[index].pnFavorited) + 1;
						}else{
							that.recommendRows[index].urId = "";
							that.recommendRows[index].pnFavorited = Number(that.recommendRows[index].pnFavorited) - 1;
						}
					}else{
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListRecommend(cnId){
				let that = this;
				let token = window.localStorage.getItem("token");
				let urId = window.localStorage.getItem("urId");
				if(token == undefined | token == null){
					token = "";
				}
				if(urId == undefined | urId == null){
					urId = "";
				}
				this.$axios({
					method:'post',
					url:'nft/pn/getReferral',
					headers:{
						token: token
					},
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId,
						cnId: cnId,
						urId: urId
					})
				})
				.then((res)=>{
					that.recommendRows = res.data.dataRoot;
					that.recommendRows.forEach((item)=>{
						let pnPrice = item.pnPrice;
						if(pnPrice == "" || pnPrice == "0.00"){
							item.pnPrice = "0"
						}
					})
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListOwner(){
				let that = this;
				let token = window.localStorage.getItem("token");
				if(token == undefined | token == null){
					token = "";
				}
				this.$axios({
					method:'post',
					url:'nft/pn/getOwers',
					headers:{
						token: token
					},
					data:this.$qs.stringify({
						pnParentId: that.$route.params.pnId,
						page: that.currentPageOwner,
						limit: that.pageSizeOwner,
						ssId: that.ssId
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.owers = res.data.owers;
					that.sales = res.data.sales;
					that.owersTotal = res.data.totalCounts;
					that.tableDataOwers = this.tableDataOwers.concat(rows);
					that.$nextTick(()=>{
						that.$refs.scrollEventOwner.addEventListener('scroll',that.scrollOwner)
					})
				})
				.catch((err)=>console.log('请求失败',err))
			},
			scrollOwner(){
				let that = this;
				const scrollTop = this.$refs.scrollEventOwner.scrollTop
				// 获取可视区的高度
				const windowHeight = this.$refs.scrollEventOwner.clientHeight
				// 获取滚动条的总高度
				const scrollHeight = this.$refs.scrollEventOwner.scrollHeight
				if (scrollTop + windowHeight >= scrollHeight ) {
					if(that.tableDataOwers.length < that.owersTotal){
						that.currentPageOwner++;
						that.getListOwner();
					}
				}
			},
			changeBox(val){
				let that =this;
				if(val){
					that.ssId = "nft_production_1";
				}else{
					that.ssId = "";
				}
				that.tableDataOwers = [];
				that.currentPageOwner = 1;
				that.getListOwner();
			},
			getListVersion(){
				let that = this;
				let token = window.localStorage.getItem("token");
				if(token == undefined | token == null){
					token = "";
				}
				this.$axios({
					method:'post',
					url:'nft/pn/getNumbs',
					headers:{
						token: token
					},
					data:this.$qs.stringify({
						pnParentId: that.$route.params.pnId
					})
				})
				.then((res)=>{
					let saleTotal = 0;
					that.versionRows = res.data.dataRoot;
					that.versionRows.forEach((item)=>{
						let ssId = item.ssId;
						if(ssId == 'nft_production_1'){
							saleTotal = saleTotal + 1;
						}
					})
					that.saleTotal = saleTotal;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			albumBtn(){
				let that = this;
				that.$router.push({
					path:'/Explore/Collection/'+ that.obj.cnId,
				})
			},
			reasonBtn(){
				let that = this;
				let tsDes = that.tsDes;
				let pnOwner = that.pnOwner;
				let pnOwnerName = that.pnOwnerName;
				let trnId = that.trnId;
				let pnAuthor = that.pnAuthor;
				if(trnId == ""){
					that.$message.error("请选择填写举报原因!");
					return false;
				}
				if(tsDes == ""){
					that.$message.error("请填写举报说明!");
					return false;
				}
				if(that.imgArr.length == 0){
					that.$message.error("请上传举报图片!");
					return false;
				}
				that.loadingReason = true;
				this.$axios({
					method:'post',
					url:'nft/ts/save',
					headers:{
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						tsId:'',
						tsDes: tsDes,
						pnId: that.$route.params.pnId,
						pnImage: that.obj.pnImage,
						pnAuthor: that.obj.pnAuthor,
						pnAuthorName: that.obj.pnAuthorName,
						pnOwner: that.obj.pnOwner,
						pnOwnerName: that.obj.pnOwnerName,
						cnId: that.obj.cnId,
						cnName: that.obj.cnName,
						trnId: that.trnId,
						trnReason: that.trnReason,
						proofs:that.imgArr.join(","),
						urId: window.localStorage.getItem("urId"),
						urName: window.localStorage.getItem("urName")
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("举报成功!");
						that.dialogReport = false;
					}else{
						that.$message.error(msg);
					}
					that.loadingReason = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			reportBtn(){//举报
				let that = this;
				let urId = window.localStorage.getItem("urId")
				if(urId == undefined || urId == null){
					this.$confirm('您还未登录，是否现在去登录', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.$router.push(({
							name:"Login"
						}))
					})
					.catch(() => {});
					return false;
				}
				this.$axios({
					method:'post',
					url:'nft/trn/getAll',
					headers:{
						token: window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({})
				})
				.then((res)=>{
					that.reasonRows = res.data.dataRoot;
					that.dialogReport = true;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListHistory(){//历史
				let that =this;
				let data = {
					pnId: that.$route.params.pnId,
					page: that.currentPage,
					limit: that.pageSize
				}
				let url = "nft/rs/getList"
				let pnType = that.$route.params.pnType;
				let token = window.localStorage.getItem("token");
				if(token == undefined | token == null){
					token = "";
				}
				if(pnType == "1"){
					url = "nft/rs/getListByParent";
					data = {
						pnParentId: that.$route.params.pnId,
						page: that.currentPage,
						limit: that.pageSize
					}
				}
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url:url,
					data:this.$qs.stringify(data)
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.total = res.data.totalCounts;
					that.tableData = this.tableData.concat(rows);
					that.$nextTick(()=>{
						that.$refs.scrollEvent.addEventListener('scroll',that.scroll)
					})
				})
				.catch((err)=>console.log('请求失败',err))
			},
			scroll(){
				let that = this;
				const scrollTop = this.$refs.scrollEvent.scrollTop
				// 获取可视区的高度
				const windowHeight = this.$refs.scrollEvent.clientHeight
				// 获取滚动条的总高度
				const scrollHeight = this.$refs.scrollEvent.scrollHeight
				if (scrollTop + windowHeight >= scrollHeight ) {
					if(that.tableData.length < that.total){
						that.currentPage++;
						that.getListHistory();
					}
				}
			},
			formattDate(rsTime){
				if(rsTime != ''){
					var rsTime = new Date(rsTime).getTime();
					return this.timeago(rsTime);
				}
			},
			getPriceStatistical(){//价格统计
				let that =this;
				let url = "nft/ds/getDeals";
				let pnType = that.$route.params.pnType;
				let token = window.localStorage.getItem("token");
				if(token == undefined | token == null){
					token = "";
				}
				if(pnType == '1'){
					url = 'nft/dpt/getDeals'
				}
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url: url,
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId,
						type: that.type
					})
				})
				.then((res)=>{
					let data = [];
					let rows = res.data.dataRoot;
					that.data = rows;
					rows.forEach((item,index)=>{
						if(pnType == '0'){
							data.push({
								year: item.dsDate,
								value: Number(item.dsAvg)
							})
						}
						if(pnType == '1'){
							data.push({
								year: item.dptDate,
								value: Number(item.dptAvg)
							})
						}
					})
					that.avg = res.data.avg;
					that.$nextTick(()=>{
						that.statistical(data);
					})
				})
				.catch((err)=>console.log('请求失败',err))
			},
			statistical(data){
				let that = this;
				let chart = null;
				let containerCurChart = null;
				containerCurChart = that.chart;
				if(containerCurChart == null){
					chart = new Chart({
						container: 'container',
						autoFit: true,
						height: 400,
					});
					chart.scale({
					  year: {
						range: [0, 1],
					  },
					  value: {
						min: 0,
						nice: true,
					  },
					});
					chart.tooltip({
					  showCrosshairs: true, // 展示 Tooltip 辅助线
					  shared: true,
					});
					chart.line().position('year*value').label('value');
					chart.point().position('year*value');
					that.chart = chart;
				}else{
					chart = containerCurChart;
				}
				chart.data(data);
				chart.render();
			},
			successUploadReason(res,file){
				this.imgArr.push(res.path);
			},
			beforeAvatarUploadReasonr(file){
				const isJPG = file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt5M = file.size / 1024 / 1024 < 100;
				if (!isJPG) {
					this.$message.error('请上传正确的图片格式!');
				}
				if (!isLt5M) {
				  this.$message.error('上传图片大小不能超过100MB!');
				}
				return isJPG && isLt5M;
				this.loading = ElLoading.service({
					lock: true,
					text: '正在上传，请稍后...',
					background: 'rgba(0, 0, 0, 0.7)',
				})
			},
			timeago(dateTimeStamp){
			    var minute = 1000 * 60;
			    var hour = minute * 60;
			    var day = hour * 24;
			    var week = day * 7;
			    var halfamonth = day * 15;
			    var month = day * 30;
			    var now = new Date().getTime();   //获取当前时间毫秒
			    var diffValue = now - dateTimeStamp;//时间差
				var result = "";
			    if(diffValue < 0){
			        return;
			    }
			    var minC = diffValue/minute;  //计算时间差的分，时，天，周，月
			    var hourC = diffValue/hour;
			    var dayC = diffValue/day;
			    var weekC = diffValue/week;
			    var monthC = diffValue/month;
			    if(monthC >= 1 && monthC <= 3){
			        result = " " + parseInt(monthC) + "月前"
			    }else if(weekC >= 1 && weekC <= 3){
			        result = " " + parseInt(weekC) + "周前"
			    }else if(dayC >= 1 && dayC <= 6){
			        result = " " + parseInt(dayC) + "天前"
			    }else if(hourC >= 1 && hourC <= 23){
			        result = " " + parseInt(hourC) + "小时前"
			    }else if(minC >= 1 && minC <= 59){
			        result =" " + parseInt(minC) + "分钟前"
			    }else if(diffValue >= 0 && diffValue <= minute){
			        result = "刚刚"
			    }else {
			        var datetime = new Date();
			        datetime.setTime(dateTimeStamp);
			        var Nyear = datetime.getFullYear();
			        var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
			        var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
			        var Nhour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
			        var Nminute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
			        var Nsecond = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
			        result = Nyear + "-" + Nmonth + "-" + Ndate
			    }
			    return result;
			},
			handleCommand(command){
				this.type = command.split("_")[0];
				this.typeName = command.split("_")[1];
				this.getPriceStatistical();
			},
			collectBtn(){
				let that = this;
				let url = "nft/pfe/save"
				let urIdStorage = window.localStorage.getItem("urId");
				let token = window.localStorage.getItem("token");
				if(token == undefined | token == null){
					token = "";
				}
				if(urIdStorage == undefined || urIdStorage == null){
					this.$confirm('您还未登录，是否现在去登录', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.$router.push(({
							name:"Login"
						}))
					})
					.catch(() => {});
					return false;
				}
				if(that.obj.urId != ""){
					url = "nft/pfe/delete"
				}
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url: url,
					data:this.$qs.stringify({
						urId: urIdStorage,
						pnId: that.$route.params.pnId,
						urName: window.localStorage.getItem("urName"),
						urHead: window.localStorage.getItem("urHead"),
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("操作成功！");
						if(that.obj.urId == ""){
							that.obj.urId = "12347";
							that.obj.pnFavorited = Number(that.obj.pnFavorited) + 1;
						}else{
							that.obj.urId = "";
							that.obj.pnFavorited = Number(that.obj.pnFavorited) - 1;
						}
					}else{
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			goToback(){
				window.open(this.obj.pnExternal);
			},
			onCopy(){
				let url = window.location.href;
				this.$copyText(url).then(() => {
					this.$message.success("当前作品链接已复制到您的粘贴板,快去分享给小伙伴吧！");
				})
			},
			changeTrnId(val){
				this.trnId = val.trnId;
				this.trnReason = val.trnReason;
			},
			getObject(){
				let that =this;
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || urId == null){
					urId = "";
				}
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'nft/pn/getView',
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId,
						urId: urId
					})
				})
				.then((res)=>{
					that.obj = res.data.data;
					that.pnPropertiesArr = JSON.parse(that.obj.pnProperties);
					that.srcList.push(that.obj.pnFile);
					if(that.obj.pnSale != ""){
						that.countTime = that.dateDifference(that.obj.sysTime,that.obj.pnSale);
						that.countDown();
					}
					let suffix = that.obj.pnFileType;
					if(suffix == ".svg" || suffix == ".png" || suffix == ".jpeg" || suffix == ".jpg" || suffix == ".gif"){
						that.type = "img";
					}
					if(suffix == ".mov" || suffix == ".mp4" || suffix == ".ogg" ||suffix == ".gltf" ||suffix == ".glb" ||suffix == ".webm" ||suffix == ".mp4" ||suffix == ".m4v" ||suffix == ".ogv"){
						that.type = "video";
					}
					if(suffix == ".mp3" || suffix == ".wav" || suffix == ".WAV" || suffix == ".OGA" || suffix == ".oga" ){
						that.type = "audio";
					}
					that.getListRecommend(that.obj.cnId);//推荐
					if(that.obj.pnMatter == "1"){
						that.getAddress();
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getAddress(){
				let that = this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'user/as/getDefault',
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId,
						urId: window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					let asMobile = res.data.data.asMobile;
					res.data.data.isAsMobile = asMobile.substring(0,7) +"****";
					that.ruleForm = res.data.data;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getService(){
				let that = this;
				that.$axios({
					method:'post',
					url: 'sys/py/getService',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId
					})
				})
				.then((res)=>{
					that.poundage = res.data.msg;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getBalance(){
				let that = this;
				that.$axios({
					method:'post',
					url: 'user/ur/getBalance',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						
					})
				})
				.then((res)=>{
					that.urAmtUsable = res.data.urAmtUsable;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			countDown(){
				let that = this;
				that.setIntervalTime = accurateInterval(1000, function() {
				  that.countTime--
				  if(that.countTime <= 0){
					that.setIntervalDownTime.cancel();
					that.setIntervalTime = null;
				  }else{
					that.timeVal = that.days(that.countTime);
				  }
				});
			},
			dateDifference(time1,time2){
				let day11 = time1.replace(/-/g, "/");
				let day22 = time2.replace(/-/g, "/");
				var day_day1 = new Date(day11);
				var day_day2 = new Date(day22);
				let disparity = day_day2.getTime() - day_day1.getTime();
				let second = Math.round(disparity / 1000);
				return second;
			},
			days(second){
				var oldsecond=second,minute=0,hour=0,day=0;
				minute = parseInt(second/60); //算出一共有多少分钟
				second%=60;//算出有多少秒
				if(minute>60) { //如果分钟大于60，计算出小时和分钟
				    hour = parseInt(minute/60);
				    minute%=60;//算出有多分钟
				}
				if(hour>24){//如果小时大于24，计算出天和小时
				    day = parseInt(hour/24);
				    hour%=24;//算出有多分钟
				}
				if(day<=0){
					return  "<font color=red>"+hour+"</font>小时<font color=red>"+minute+"</font>分<font color=red>"+second+"</font>秒";
				}else{
					return  "<font color=red>" + day +  "</font>"+"天<font color=red>"+hour+"</font>小时<font color=red>"+minute+"</font>分<font color=red>"+second+"</font>秒";
				}
			}
		},
		destroyed(){
		    this.setIntervalTime.cancel();
		    this.setIntervalTime = null;
		}
	};
</script>
<style scoped>
	.AddressBox{
		coolor:rgb(129, 129, 129);
		font-size:18px;
		margin:10px 0px;
		font-weight: bold;
	}
	.Address{
		display: flex;
		justify-content: space-between;
		margin:10px 0px;
	}
	.Address div:first-child{
		font-size: 16px;
		color: #000;
	}
	.Address div:last-child{
		font-size: 18px;
		font-weight: bold;
		color:rgba(26, 131, 252, 1);
	}
	.play{
		width:50px;
		height:50px;
		position: absolute;
		top:50%;
		left:50%;
		margin-left:-25px;
		margin-top:-25px;
	}
	.IconImgList{
	    display: flex;
	    justify-content: space-between;
	    color: rgb(112, 122, 131) !important;
	    padding: 12px;
	    background: linear-gradient(rgba(229, 232, 235, 0.392) 0%,rgb(255, 255, 255) 20%);
	    border-bottom-left-radius: 10px;
	    border-bottom-right-radius: 10px;
	}
	.IconImgList img{
	    width: 20px !important;
	    height: 20px !important;
	    vertical-align: bottom;
	    margin-right: 5px;
	}
	.IconImgList span{
	    width: 50%;
	}
	.IconImgList span:last-child {
	    text-align: right;
	}
	.ownerBox{
		padding:0px 30px;
		margin-top:15px 0px;
		display: flex;
		justify-content: space-between;
	}
	.owner{
		width:50%;
	}
	.owner:first-child{
		font-size:16px;
		font-weight:bold;
	}
	.owner:first-child font{
		color:red;
		font-weight:bold;
	}
	.owner:last-child{
		text-align: right;
	}
	.version{
		font-size: 16px;
		font-weight: bold;
		margin:20px 0px;
		padding:0px 30px;
	}
	.version font{
		color: red;
		font-size: 24px;
		font-weight: bold;
	}
	.list{
		height:200px;
		margin:15px 0px;
	}
	.list div{
		width:38px;
		height:38px;
		line-height: 38px;
		text-align: center;
		color:#fff;
		margin-right:10px;
		margin-bottom:10px;
		border-radius: 5px;
		background:rgb(153, 153, 153);
		float:left;
	}
	.list .box{
		background:#409EFF;
	}
	.IconHistory{
		width:20px;
		height:20px;
		vertical-align: middle;
	}
	.statisticalBox{
		margin-bottom:20px;
	}
	.nature{
		height:auto;
		overflow: hidden;
		margin-bottom:20px;
	}
	.nature ul li{
		width: 180px;
		height: 60px;
		text-align: center;
		vertical-align:middle;
		background: rgb(237, 248, 253);
		border:1px solid rgb(60, 178, 229);
		font-size:14px;
		font-weight:bold;
		float: left;
		margin-right:10px;
		margin-bottom:10px;
		padding-top:10px;
		box-sizing: border-box;
	}
	.nature ul li font{
		color:rgba(60, 178, 229, 1);
	}
	.momeyBox{
		width:100%;
		border-bottom:2px solid #999;
		margin:25px 0px;
	}
	.momeyBox .name{
		border-left:4px solid #999;
		padding:5px;
		font-size:20px;
		font-weight:bold;
	}
	.select{
		font-size:18px;
		color:#000;
		font-weight:bold;
	}
	.el-dropdown-link{
	    font-size: 18px;
	    color: #000;
	    margin-left: 8px;
		font-weight:bold;
	}
	.priceBox{
		display: flex;
		justify-content:space-between;
		margin:10px 0px;
	}
	.priceBox .price{
		font-size:16px;
		font-weight: bold;
	}
	.priceBox .price font{
		color:red;
		font-size: 24px;
		font-weight: bold;
	}
	.mainBox{
		max-width: 1280px;
		margin:0px auto;
		height: auto;
	}
	.mainTop{
		height: auto;
		overflow: hidden;
		padding:20px 0px;
		box-sizing: border-box;
	}
	.mainLeftBox{
		width:49%;
		height:600px;
		text-align: center;
		border-radius: 5px;
		float:left;
		position: relative;
	}
	.mainRightBox{
		width:49%;
		height:600px;
		float:right;
		position: relative;
	}
	.mainLeftBox img{
		object-fit:cover;
	}
	.mainRightBox .title{
		font-size:24px;
		font-weight:bold;
	}
	.mainRightBox .IconImg{
		font-size:16px;
		margin-top:10px;
	}
	.mainRightBox .IconImg img{
		width:20px;
		height:20px;
		vertical-align: sub;
	}
	.mainRightBox .album{
		font-size: 16px;
		font-weight:bold;
		margin-top:10px;
	}
	.mainRightBox .timeBox{
		height:47px;
		line-height: 47px;
		margin-top:10px;
		display: flex;
		justify-content: space-between;
		margin-top:20px;
	}
	.mainRightBox .timeBox font{
		color:red;
	}
	.mainRightBox .timeBox .money{
		width:50%;
		font-size:36px;
		color:red;
		font-weiht:bold;
	}
	.mainRightBox .timeBox .time{
		width:50%;
		font-weight: bold;
		margin-right:30px;
	}
	.mainRightBox .author{
		font-weight:bold;
		margin-top:15px;
	}
	.mainRightBox .author font{
		color:#409EFF;
	}
	.mainRightBox .works{
		font-weight: bold;
		margin-top:10px;
	}
	.mainRightBox p{
		text-indent: 2em;
		margin-top:10px;
		line-height: 20px;
	}
	.el-button{
	    height: 50px;
	    /* color: rgb(112, 122, 131); */
	    font-size: 16px;
	    font-weight: 600;
	}
	.IconBtn{
		position: absolute;
		top:0px;
		right:30px;
	}
	.button .Icon img{
		width: 20px !important;
		height: 20px !important;
		vertical-align: sub;
		margin-right: 5px;
	}
	.button{
		width:100%;
		display: flex;
		justify-content: space-between;
		position: absolute;
		bottom:0px;
	}
	.button div{
		width:50%;
	}
	.button .el-button{
		height:40px;
		width:95px;
		/* color:#fff; */
	}
	.button .is-plain{
		/* color:#409EFF */
	}
	.button .Icon{
		line-height:40px;
		text-align: right;
	}
	.boxListCon{
		padding:0px 20px;
	}
	.boxListCon .cart{
		font-size:16px;
		font-weight:bold;
	}
	.boxListCon .cart .el-icon{
		vertical-align: sub;
	}
	.user{
		display: flex;
	}
	.user .text{
		align-self: center;
	}
	.user span{
		display: inline-block;
	}
	.user span:first-child{
		vertical-align: bottom;
	}
	.user .text div:first-child{
		color:#909399;
	}
	.user .text div:last-child{
		color:#000;
		font-weight:bold;
	}
	.user img{
		width:30px;
		height:30px;
		border-radius: 10px;
		vertical-align: text-top;
		margin-right:8px;
	}
	.el-upload .change{
		text-align: center;
		padding:10px 0px;
	}
	.el-upload .change:hover{
		background:#d9d9d9;
	}
	.dialog-footer .el-button{
		height:40px;
	}
	.dialog-footer .el-button--primary{
		color:#fff;
	}
	.dialogSell .title{
		margin:15px 0px;
		font-weight: bold;
		font-size:16px;
	}
</style>
